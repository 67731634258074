.timer{
    display: flex;
    .timer{ 
        &-divider{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: calc(var(--spacing-2x) - 2px) var(--spacing-2x);
            > svg:first-of-type{
                margin-bottom: calc(var(--spacing-1x) + 2px );
            }
        }
        &-box{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: var(--border-radius-base);
            &-num{
                display: flex;
                justify-content: center;
                color: var(--text-primary-color);
                font-size: var(--font-size-h3);
                line-height: var(--line-height-h3);
                font-weight: var(--font-weight-bold);
            }
            &-label{
                display: flex;
                justify-content: center;
                color: var(--text-body-color);
                font-size: var(--font-size-md);
                line-height: var(--line-height-md);
                font-weight: var(--font-weight-base);
                &-short{
                    display: none;
                }
            }
            &.is-bordered{
                border: 1px solid var(--gray-lighter-4);
            }
            &.is-boxed{
                background: var(--ui-block-bg);
                box-shadow: var(--box-shadow-lg);
            }
            &.is-bordered,
            &.is-boxed{
                padding: var(--spacing-1x) var(--spacing-2x);
                min-width: 86px;
            }
            &.is-separated{
                .timer-box{
                    &-num{
                        position: relative;
                        min-width: 62px;
                        &:before,
                        &:after{
                            content: '';
                            display: flex;
                            background: var(--cms-block-bg);
                            box-shadow: var(--cms-block-shadow);
                            width: 29px;
                            min-width: 29px;
                            border-radius: var(--border-radius-base);
                            position: absolute;
                            top: 0;
                            bottom: 0;
                        }
                        &:before{
                            left: 0;
                        }
                        &:after{
                            right: 0;
                        }
                        &-text{
                            letter-spacing: 12px;
                            margin-right: -12px;
                            z-index: 1;
                        }
                    }
                    &-label{
                        margin-top: 4px;
                    }
                }
            }
        }
        &-loader{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            @keyframes rotating {
                from{
                    -webkit-transform: rotate(0deg);
                }
                to{
                    -webkit-transform: rotate(360deg);
                }
            }
            > .loader{
                -webkit-animation: rotating 1.5s linear infinite;
            }
        }
    }
    &.is-loading{
        .timer-loader{
            opacity: 1;
            visibility: visible; 
        }
        .timer-box{
            > div:not(.timer-loader){
                visibility: hidden;
            }
        }
    }
    &-bordered,
    &-boxed{
        .timer{
            &-divider{
                justify-content: center;
            }
        }
    }
    &-sm{
        .timer-box{
            &-num{
                font-size: var(--font-size-h4);
                line-height: var(--line-height-h4);
                font-weight: var(--font-weight-bold);
            }
            &-label{
                font-size: var(--font-size-xs);
                line-height: var(--line-height-xs);
                font-weight: var(--font-weight-base);
            }
            &.is-bordered,
            &.is-boxed{
                min-width: 68px;
            }
            &.is-separated{
                .timer-box{
                    &-num{
                        min-width: 50px;
                        &:before,
                        &:after{
                            content: '';
                            width: 23px;
                            min-width: 23px;
                            border-radius: var(--border-radius-sm);
                        }
                    }
                }
            }
        }
        .timer-divider{
            padding: calc(var(--spacing-2x) - 4px) calc(var(--spacing-2x) - 4px);
            > svg:first-of-type{
                margin-bottom: calc(var(--spacing-1x) - 2px );
            }
            > svg{
                circle{
                    fill: var(--gray-base);
                }
            }
        }
    }
    &-xs{
        .timer-box{
            &-num{
                font-size: var(--font-size-h6);
                line-height: var(--line-height-h6);
                font-weight: var(--font-weight-medium);
            }
            &-label{
                font-size: var(--font-size-xs);
                line-height: var(--line-height-xs);
                font-weight: var(--font-weight-base);
            }
            &.is-bordered,
            &.is-boxed{
                padding: var(--spacing-1x) calc(var(--spacing-1x) + 2px);
                min-width: 58px;
            }
            &.is-separated{
                .timer-box{
                    &-num{
                        min-width: 40px;
                        &:before,
                        &:after{
                            content: '';
                            width: 19px;
                            min-width: 19px;
                        }
                        &-text{
                            letter-spacing: 11px;
                            margin-right: -11px;
                        }
                    }
                    &-label{
                        margin-top: 2px;
                    }
                }
            }
        }
        .timer-divider{
            padding: var(--spacing-1x) calc(var(--spacing-1x) + 2px);
        }
    }
}
.site-section{
    .timer{
        margin-top: var(--spacing-5x);
        margin-bottom: var(--spacing-5x);
        @include media-breakpoint-down(sm){
            margin-top: var(--spacing-4x);
            margin-bottom: var(--spacing-4x);
            justify-content: center;
        }
    }
}
.site-banner.banner{
    &.banner-center{
        .timer{
            justify-content: center;
        }
    }
    .timer{
        margin-top: var(--spacing-4x);
        margin-bottom: var(--spacing-1x);
        @include media-breakpoint-down(sm){
            margin-top: var(--spacing-4x);
            margin-bottom: var(--spacing-2x);
            justify-content: center;
        }
    }
}

@include media-breakpoint-down(lg){
    .timer{
        .timer{
            &-divider{
                padding: calc(var(--spacing-2x) - 6px) calc(var(--spacing-2x) - 6px);
            }
            &-box{
                &-label{
                    display: none;
                    &-short{
                        display: flex;
                    }
                }
                &.is-bordered,
                &.is-boxed{
                    min-width: 76px;
                }
            }
        }
        &-sm{
            .timer-box{
                &.is-bordered,
                &.is-boxed{
                    min-width: 64px;
                }
            }
            .timer-divider{
                padding: calc(var(--spacing-2x) - 2px) calc(var(--spacing-2x) - 4px);
            }
        }
        &-xs{
            .timer-box{
                &.is-bordered,
                &.is-boxed{
                    min-width: 48px;
                }
            }
            .timer-divider{
                padding: var(--spacing-1x) var(--spacing-1x);
            }
        }
    }
}
@include media-breakpoint-down(sm){
    .timer{
        .timer{
            &-box{
                &.is-bordered,
                &.is-boxed{
                    min-width: 72px;
                }
                &.is-separated{
                    .timer-box-num{
                        min-width: 58px;
                        &:before,
                        &:after{
                            width: 27px;
                            min-width: 27px;
                        }
                    }
                }
            }
        }
        &-sm{
            .timer-box{
                &.is-bordered,
                &.is-boxed{
                    min-width: 60px;
                }
                &.is-separated{
                    .timer-box-num{
                        min-width: 46px;
                        &:before,
                        &:after{
                            width: 21px;
                            min-width: 21px;
                        }
                    }
                }
            }
        }
        &-xs{
            .timer-box{
                &.is-bordered,
                &.is-boxed{
                    min-width: 46px;
                }
                &.is-separated{
                    .timer-box-num{
                        min-width: 38px;
                        &:before,
                        &:after{
                            width: 18px;
                            min-width: 18px;
                        }
                    }
                }
            }
        }
    }
}



.alert.alert-type-primary:not(.alert-primary),
.alert.alert-secondary:not(.alert-primary){
    .timer{
        .timer{
            &-divider{
                > svg{
                    circle{
                        fill: var(--text-secondary-lighter-color);
                    }
                }
            }
            &-box{
                &.is-bordered,
                &.is-separated,
                &.is-default{
                    .timer-box-label{
                        color: var(--text-secondary-lighter-color);
                    }
                    .timer-loader{
                        > .loader{
                            > div{
                                border-color: var(--text-secondary-heading-color) transparent transparent transparent;
                            }
                        }
                    }
                }
                &.is-bordered,
                &.is-default{
                    .timer-box-num{
                        color: var(--text-secondary-heading-color);
                    }
                }
            }
        }
    }
}
