$isrtl: true!default;

@import 'variables.scss';
@import 'helpers/functions.scss';
@import 'elements/timer.scss';


.error-page{
    .alert-primary{
        .container{
            flex-direction: row;
        }
    }
    .custom-alerts{
        margin-right: auto;
        margin-left: auto;
        .clientAlert{
            width: 100%;
        }
        @include media-breakpoint-up(sm){
            max-width: 540px;
        }
        @include media-breakpoint-down(sm){
            padding-left: var(--layout-container-gutter);
            padding-right: var(--layout-container-gutter);
        }
        @include media-breakpoint-up(md){
            width: 100%;
            max-width: calc(var(--layout-container-gutter) + var(--container-xl-max-width));
            padding-left: var(--layout-container-gutter);
            padding-right: var(--layout-container-gutter);
        }
    }
} 

.alert.alert-primary,
.clientAlertModal{
    .modal-body,
    .alert-body{
        span[style^="font-weight: 700"],
        b,
        strong{
            color: var(--text-heading-color); 
        }
    }
    &.modal{
        &-success{
            .alert-icon{
                &:before{
                    color: var(--brand-success);
                }
            }
        }
        &-info{
            .alert-icon{
                &:before{
                    color: var(--brand-primary);
                }
            }
        }
        &-danger{
            .alert-icon{
                &:before{
                    color: var(--brand-danger);
                }
            }
        }
        &-warning{
            .alert-icon{
                &:before{
                    color: var(--brand-warning);
                }
            }
        }
        &-default{
            .alert-icon{
                &:before{
                    color: var(--gray-icons);
                }
            }
        }
    }
    .modal-header{
        &.modal-header-no-icon{
            .modal-title{
                .alert-icon{
                    display: none;
                }
            }
        }
    }
    .modal-content{
        .close{
            &.close-oncontent{
                padding: var(--modal-btn-close-padding);
                z-index: 1;
                right: 28px;
                top: 16px;
        
                >span { 
                    padding-bottom: 4px;
                }
    
                i {
                    font-size: 14px!important;
                }
            }
        }
    }
    .modal-title{
        padding-right: var(--spacing-4x); 
        &.modal-title-icon-replaced{
            &:before{
                display: none;
            }
            .alert-icon{
                margin-right: var(--spacing-2x);
            }
        }
    }
    .btn{
        &.btn-link{
            background: transparent;
            border-color: transparent;
            &:hover{
                background: transparent;
                border-color: transparent;
            }
            &.btn-secondary{color: var(--btn-primary-faded-color); &:hover{color: var(--btn-primary-faded-hover-color);}}
            &.btn-default{color: var(--btn-default-color); &:hover{color: var(--btn-default-hover-color);}}
            &.btn-info{color: var(--btn-info-color); &:hover{color: var(--btn-info-hover-color);}}
            &.btn-success{color: var(--btn-success-color); &:hover{color: var(--btn-success-hover-color);}}
            &.btn-danger{color: var(--btn-danger-color); &:hover{color: var(--btn-danger-hover-color);}}
            &.btn-warning{color: var(--btn-warning-color); &:hover{color: var(--btn-warning-hover-color);}}
        }
    }
    .modal-footer{
        flex-wrap: wrap-reverse;
        .alert-buttons{
            padding-left: 0;
            > .btn{
                &:not(:last-of-type){
                    margin-right: var(--modal-footer-margin-between);
                    margin-left: 0;
                }
            }
        }
        &.footer-has-checkbox{
            &.justify-content-center{
                flex-direction: column;
                .checkbox{
                    margin: var(--spacing-3x) auto 0 auto!important;
                }
            }
            &.justify-content-end{
                flex-direction: row-reverse;
                .checkbox{
                    margin-left: 0!important;
                    margin-right: auto!important;
                }
            }
        }
    }
}

.alert-primary{
    background: var(--ui-block-bg);
    // background-color: unset!important;
    .alert-body{
        p{
            color: var(--text-body-color)!important;
        }
    } 
    @include media-breakpoint-down(sm){
        .alert-body{
            align-self: auto;
        }
    }
}
.app-main{
    > .main-body{
        > .container,
        > .container-fluid{
            .custom-alerts{ 
                .clientAlert{
                    >.container{
                        display: flex;
                        padding-left: 0!important;
                        padding-right: 0!important;
                    }
                }
                .alert{
                    &:not(:last-child){
                        margin-bottom: 0;
                    }
                    & + .alert{
                        &:not(:first-child){
                            margin-top: var(--spacing-2x);
                        }
                    }
                }
            }
        }
    }
}

.login-body{
    > .custom-alerts{
        .clientAlert{
            > .container{
                display: flex;
                flex-direction: row;
                padding-left: 0!important; 
                padding-right: 0!important; 
            }
        }
    }
}
.login{
    &:not(.login-lg){
        .custom-alerts{
            .clientAlert{
                > .container{
                    .alert-content{
                        flex-direction: column;
                        align-self: flex-start;
                        .alert-body{
                            align-self: flex-start;
                        }
                        .alert-actions{
                            width: 100%;
                            padding: var(--spacing-1x) 0 0 0;
                            .btn{
                                margin-top: var(--spacing-1x);
                            }
                        }
                    }
                }
            }
        }
    }
}


//Alerts site
.app-main{
    > .custom-alerts{
        .alert{
            margin-bottom: 0;
            border-radius: 0;
            box-shadow: none;
            padding-left: 0;
            padding-right: 0;
            &:not(.alert-type-primary):not(.alert-secondary){
                border-bottom: 1px solid var(--ui-block-divider-color);
            }
            z-index: 2;
            > .container{
                display: flex;
            }
            &.alert-dismissible{
                padding-right: 0!important;
                @include media-breakpoint-down(sm){
                    .alert-content{
                        padding-right: var(--layout-container-gutter);
                    }
                }
            }
            &:before{
                display: none;
            }
        }
    }
    > .email-verification{
        padding-left: 0;
        padding-right: 0;
    }
}

.clientAlertModal{
    &.prevent-close{
        &.modal-static{
            .modal-dialog{
                transform: none;
            }
        }
    }
    // &.modal-lg{
    //     @include media-breakpoint-up(lg){
    //         .modal-dialog{
    //             width: 640px;
    //         }
    //     }
    // }
    .modal{
        &-content{
            overflow: hidden;
        }
        &-graphic{
            display: flex;
            justify-content: center;
            .alert{
                &-custom{
                    &-graphic{
                        &-container{
                            display: flex;
                            justify-content: center;
                            max-width: 100%;
                            height: auto;
                            svg,
                            img{
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                &-predefined{
                    &-illustration,
                    &-icon{
                        &-container{
                            display: flex;
                            justify-content: center;
                            align-items: center; 
                            position: relative;
                            align-self: center;
                            max-width: 100%;
                            &:not(.promo-illustration){
                                margin-top: var(--spacing-3x);
                            }
                            .promo-illustration{
                                max-width: 340px;
                                min-width: 340px;
                                width: 340px;
                                height: 300px;

                            }
                            .promo-slider-icon{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0px; 
                            }
                        }
                    }
                    &-icon-container{
                        margin-top: var(--spacing-5x);
                    }
                }
            }
            &.justify-content{
                &-start,
                &-center{ 
                    .alert-predefined-icon-container{
                        padding: 0 var(--spacing-3x);
                    }
                }
                &-end{
                    .alert-predefined-icon-container{
                        padding: 0 var(--spacing-8x) 0 var(--spacing-3x);
                    }
                }
            }
            .modal-predefined-background{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                overflow: hidden;
            }
        }
        &-body{
            word-break: break-word;
            > p{
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
    &.modal{
        .modal-graphic{
            position: relative;
            .modal-predefined-background{
                > svg{
                    position: absolute;
                    right: -375px;
                    top: 0;
                    bottom: 0;
                    height: 279px;
                }
            }
            .alert-predefined-illustration-container{
                &.promo-illustration{
                    min-width: 272px;
                    max-width: 272px;
                    height: 240px;
                    > svg{
                        width: 272px;
                    }
                }
            }
        }
    }
    &.modal-lg{
        .modal-graphic{
            .modal-predefined-background{
                > svg{
                    position: absolute;
                    right: -478px;
                    top: 0;
                    bottom: 0;
                    height: 355px;
                }
            }
            .alert-predefined-illustration-container{
                &.promo-illustration{
                    min-width: 340px;
                    max-width: 340px;
                    height: 300px;
                    > svg{
                        width: 340px;
                    }
                }
            }
        }
    }
    &.modal-sm{
        .modal-graphic{
            .modal-predefined-background{
                > svg{
                    position: absolute;
                    right: -260px;
                    top: 0;
                    bottom: 0;
                    height: 192px;
                }
            }
            .alert-predefined-illustration-container{
                &.promo-illustration{
                    min-width: 180px;
                    max-width: 180px;
                    height: 159px;
                    > svg{
                        width: 180px;
                    }
                }
            }
        }
    }
    &.modal-custom-icon{
        .modal{
            &-graphic{
                .alert-custom-graphic-container{
                    margin-top: var(--spacing-3x);
                    padding: 0 var(--spacing-3x) 0 var(--spacing-3x);
                }
                &.justify-content-end{
                    .alert-custom-graphic-container{
                        padding: 0 var(--spacing-8x) 0 var(--spacing-3x);
                    }
                }
            }
        }
    }
}

.modal-open{
    .modal{
        &.clientAlertModal{
            @include media-breakpoint-down(md){
                display: block !important;
                overflow-y: auto;
                .modal-body{
                    overflow-y: hidden;
                    max-height: unset;
                }
            }
        }
    }
    
}

.clientAlert{
    .alert{
        &-custom-image,
        &-predefined-icon,
        &-predefined-illustration{
            &-container{
                display: flex;
                // justify-content: center;
                // align-items: center; 
                position: relative;
                max-width: 160px;
                margin: 0 var(--spacing-2x) 0 0; 
                align-self: baseline;
                svg,
                img{
                    max-width: 100%;
                }
                &.promo-illustration{
                    min-width: 160px;
                    height: 140px;
                    .promo-slider-icon{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0px; 
                        width: 160px;
                        height: 140px;
                    }
                }
            }
        }
        &-predefined-icon-container,
        &-predefined-illustration-container{
            height: auto;
            > svg{
                height: auto;
                
            }
        }
        &-body{
            .alert-actions{
                margin-top: calc(-1 * var(--spacing-2x));
                margin-bottom: var(--spacing-1x);
            }
        }
    }
    &.alert-icon-v-center{ 
        .alert-custom-image-container,
        .alert-predefined-icon-container,
        .alert-predefined-illustration-container{
            align-self: center;
        }
    }
    // &.alert-center{
    //     > .container{
    //         flex-direction: column;
    //     }
    //     .alert{
    //         &-custom-image,
    //         &-predefined-icon,
    //         &-predefined-illustration{
    //             &-container{
    //                 margin: 0 auto var(--spacing-2x) auto;
    //             }
    //         }
    //         &-body{
    //             .alert-title,
    //             > p{
    //                 text-align: center;
    //             }
    //             .alert-actions{
    //                 display: flex;
    //                 justify-content: center;
    //             }
    //         }
    //     }

    // }
}

// RLT

[dir="rtl"]{
    .clientAlert{
        .alert{
            &-custom-image,
            &-predefined-icon,
            &-predefined-illustration{
                &-container{
                    margin: 0 0 0 var(--spacing-2x);
                }
            }
        }
    }
    .clientAlertModal{
        .modal-title{
            &.modal-title-icon-replaced{
                .alert-icon{
                    margin-left: var(--spacing-2x);
                    margin-right: 0;
                }
            }
        }
        .modal-footer{
            &.justify-content-end{
                flex-direction: row;
                justify-content: flex-start!important;
            }
            &.justify-content-start{
                flex-direction: row-reverse;
                justify-content: flex-end!important;
            }
        }
        .modal-graphic{
            &.justify-content-end{
                justify-content: flex-start!important;
            }
            &.justify-content-start{
                justify-content: flex-end!important;
            }
        }
    }
}