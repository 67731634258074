$isrtl: false !default;
$style: 'default' !default; // default, depth, modern, futuristic, integration

$bootstrap-sass-asset-helper: false;

//== Colors
@function v($var, $fallback: null) {
	@if($fallback) {
		@return var(--#{$var}, #{$fallback});
	} @else {
		@return var(--#{$var});
	}
}

$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/") ;

$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;
$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

$screen-mob-max:             $screen-xs-min - 1;
$screen-xs-max:              $screen-sm-min - 1;
$screen-sm-max:              $screen-md-min - 1;
$screen-md-max:              $screen-lg-min - 1;
$screen-lg-max:              $screen-lg - 1;

$grid-breakpoints: (
  xs: 0px, 
  sm: 480px,
  md: 768px,
  lg: 992px,
  xl: 1320px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 100%,
  lg: 100%,
  xl: 1288px
) !default;

//== Container sizes

$container-gutter:             32px;
$container-tablet:             750px; 
$container-sm:                 $container-tablet ;
$container-desktop:            960px; 
$container-md:                 $container-desktop ;
$container-large-desktop:      (1128px + $container-gutter) ;
$container-lg:                 $container-large-desktop ;


$enable-grid-classes:          true !default;
$enable-rounded:               true !default;
$enable-shadows:               true !default;
$enable-transitions:               true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-deprecation-messages:   true !default;
$enable-validation-icons:       true !default;
$enable-gradients:              true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-caret:                  true !default;
$emphasized-link-hover-darken-percentage: 15% !default;

$body-bg:                   #fff !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// Needed for red(), green(), blue() functions in bootrstrap/_functions.scss 'color-yiq' function
$form-feedback-valid-color:                    #36C055 !default;
$form-feedback-invalid-color:                  #d92632 !default;

/* The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255. */
$yiq-contrasted-threshold:                     150;

/* Customize the light and dark text colors for use in our YIQ color contrast function. */
$yiq-text-dark:                                #212529;
$yiq-text-light:                               #fff;

$form-validation-states: () !default;
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": var(--form-feedback-icon-valid)
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": var(--form-feedback-icon-invalid)
    ),
  ),
  $form-validation-states
);

//== Grid system

$grid-columns:              12;
$grid-gutter-width:         24px !default;
$grid-row-columns:          6 !default;
$grid-float-breakpoint:     992px;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;
$user-selects: all, auto, none !default;